<template>
  <SubHeader> Fermentation Graph </SubHeader>
  <CardBox>
    <canvas id="fermentationGraph" height="400" width="800"></canvas>

    <!-- <Row class="mt-2">
      <BCol>
        <FormInputEnableable
          v-model.number="fg"
          ref="formFG"
          type="number"
          :validateion="validNum"
          span="kg/m³"
          @lock="setFG(fg)"
        />
      </BCol>
    </Row> -->
  </CardBox>
</template>

<script>
import comp from "../base"
import { Chart } from "chart.js"
// import { validNum, checkValidity } from "../../services/validation"
import {
  regressionData,
  // estimatedEnd,
  dataLog,
  r2,
  chartOptions,
  chartData,
} from "../../services/chart"
// import cloneDeep from "clone-deep"
// import { useStore } from '@/store/store'

export default {
  name: "FermentationChart",
  components: {
    ...comp,
  },
  props: ["popBrew"],
  setup() {
    let chart
    return {
      chart,
      // validNum,
    }
  },
  // data() {
  //   return {
  //     fg: null,
  //   }
  // },
  // created() {
  //   this.updateData()
  // },
  mounted() {
    this.chart = new Chart(document.querySelector("#" + "fermentationGraph"), {
      type: "scatter",
      data: chartData,
      options: chartOptions,
    })
    this.updateChart()
    // this.updateData()
  },
  watch: {
    popBrew() {
      this.updateChart()
      // this.updateData()
    },
  },
  methods: {
    // setFG(value) {
    //   let copyBrew = cloneDeep(useStore().state.collections.brews[this.popBrew.id])
    //   copyBrew.recipeCopy.fg = value
    //   copyBrew.endEstimated = estimatedEnd(copyBrew)

    //   if (checkValidity(this.$refs)) apiSetBrew(copyBrew)
    // },
    // updateData() {
    //   this.fg = this.popBrew ? this.popBrew.recipeCopy.fg : null
    // },
    updateChart() {
      this.chart.data.datasets[0].data = dataLog(this.popBrew)
      this.chart.data.datasets[1].data = regressionData(this.popBrew)
      this.chart.data.datasets[1].label = "Exponential Regeression r2 = " + r2(this.popBrew)
      // this.chart.options.scales.xAxes[0].ticks.min = this.popBrew.start.valueOf()
      // this.chart.options.scales.xAxes[0].ticks.max = this.end.valueOf()
      this.chart.update()
    },
  },
}
</script>
