<template>
  <Row>
    <BCol>
      <FormInputEnableable
        ref="formSG"
        type="number"
        v-model.number="sg"
        :validateion="validNum"
        span="kg/m³"
        @lock="setLog()"
      />
    </BCol>
    <BCol>
      <FormInputEnableable
        ref="formTime"
        type="datetime-local"
        :modelValue="formatTime(time)"
        @update:modelValue="val => (time = new Date(val))"
        @lock="setTime()"
      />
    </BCol>
    <Button class="col-2 mx-1" @click="trash(index)">trash</Button>
  </Row>
</template>

<script>
import comp from "../base"
import { format } from "date-fns"
import cloneDeep from "clone-deep"
import { apiSetBrew } from "../../services/api"
import { estimatedEnd } from "../../services/chart"
import { checkValidity, formError, validNum } from "../../services/validation"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"
import { handleDate } from "@/helper/helper"

export default {
  name: "FermentationLogItem",
  props: ["item", "index"],
  components: {
    ...comp,
  },
  setup() {
    return {
      validNum,
    }
  },
  data() {
    return {
      brewId: this.$route.params.id,
      sg: null,
      time: null,
      brews: computed(() => useStore().state.collections.brews),
    }
  },
  created() {
    this.setData()
  },
  watch: {
    item() {
      this.setData()
    },
  },
  methods: {
    setData() {
      this.sg = this.item ? this.item.sg : null
      this.time = this.item ? handleDate(this.item.time) : null
    },
    // temp(val) {
    //   let temp = new Date(val)
    // },
    setLog() {
      let local = cloneDeep(useStore().state.collections.brews[this.brewId])
      local.fermentationLog[this.index].sg = this.sg

      if (checkValidity(this.$refs.formSG)) apiSetBrew(local)
      else formError()
    },
    setTime() {
      let local = cloneDeep(useStore().state.collections.brews[this.brewId])
      local.fermentationLog[this.index].time = new Date(this.time)
      local.endEstimated = estimatedEnd(local)

      if (checkValidity(this.$refs.formTime)) apiSetBrew(local)
      else formError()
    },
    formatTime(time) {
      let date = time instanceof Date ? time : time.toDate()
      return format(date, "yyyy-MM-dd") + "T" + format(date, "hh:mm")
    },
    trash(index) {
      let local = cloneDeep(useStore().state.collections.brews[this.brewId])
      local.fermentationLog.splice(index, 1)
      local.endEstimated = estimatedEnd(local)
      apiSetBrew(local)
    },
  },
}
</script>
