
import BrewRecyme from "../recymes/BrewRecyme.vue"
import FermentationLog from "./FermentationLog.vue"
import FermentationGraph from "./FermentationGraph.vue"

import comp from "../base"
import { useStore } from "@/store/store"
import { useRoute } from "vue-router"
import { computed, defineComponent } from "@vue/runtime-core"

export default defineComponent({
  name: "FermentationSheet",
  components: {
    ...comp,
    BrewRecyme,
    FermentationLog,
    FermentationGraph,
  },
  setup() {
    return {
      popBrew: computed(() => useStore().getters.popBrew(useRoute().params.id as string)),
    }
  },
  computed: {
    // popBrew() {
    //   return useStore()
    // },
  },
})
