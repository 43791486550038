<template>
  <SubHeader> Fermentation Log </SubHeader>
  <CardBox>
    <Row class="mb-3">
      <BCol>
        <FormInput
          class="form-control-sm"
          type="number"
          placeholder="Specific gravity [NUMBER]"
          ref="formValue"
          :validateion="validNum"
          v-model.number="value"
        />
      </BCol>
      <BCol>
        <FormGroup>
          <FormInput ref="formTime" class="form-control-sm" type="datetime-local" v-model="time" />
          <Button @click="now">Now</Button>
        </FormGroup>
      </BCol>
      <Button class="col-2 mx-1" @click="add()">Add</Button>
    </Row>
    <div class="mb-2" v-for="(item, index) in brew.fermentationLog" :key="index">
      <FermentationLogItem :item="item" :index="index" />
    </div>
  </CardBox>
</template>

<script>
// formatTime
import comp from "../base"
import { format } from "date-fns"
import { apiSetBrew } from "../../services/api"
// import { estimatedEnd } from "../../services/chart"
import { validNum, checkValidity, untouch, formError } from "../../services/validation"
import FermentationLogItem from "./FermentationLogItem.vue"
import { useStore } from "@/store/store"
import { cloneDeep } from "lodash"

export default {
  name: "FermentationLog",
  // props: ["brewId"],
  components: {
    ...comp,
    FermentationLogItem,
  },
  setup() {
    return {
      validNum,
    }
  },
  data() {
    return {
      brewId: this.$route.params.id,
      time: null,
      value: null,
    }
  },
  created() {
    this.now()
  },
  computed: {
    brew() {
      return this.brewId ? useStore().getters.brew(this.brewId) : null
    },
  },
  methods: {
    formatTime(time) {
      let date = time instanceof Date ? time : time.toDate()
      return format(date, "yyyy-MM-dd") + "T" + format(date, "hh:mm")
    },
    now() {
      this.time = format(new Date(), "yyyy-MM-dd") + "T" + format(new Date(), "hh:mm")
    },
    add() {
      if (checkValidity(this.$refs)) {
        let local = cloneDeep(useStore().state.collections.brews[this.brewId])
        local.fermentationLog.push({ time: new Date(this.time), sg: this.value })
        // local.endEstimated = estimatedEnd(local) // dette bør overgå til getter.
        apiSetBrew(local)
        this.value = null
        untouch(this.$refs)
      } else formError()
    },
  },
}
</script>
